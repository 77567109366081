import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Image from '../components/Image';
import type { Recipe } from '../types/recipes';
import { getCategoryName } from '../utils/categories';

const Header = styled.div`
  background: #b0d9f9;
  color: #272b46;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
const Container = styled.div`
  background: #b0d9f9;
  color: #272b46;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
`;
const Result = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 8px;
  text-align: center;
  align-items: center;
  width: 150px;

  :hover {
    box-shadow: 0 0 3px 2px #272b46;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 150px;
  overflow: hidden;
`;

const CategoryPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [recipes, setRecipes] = useState<Array<Recipe>>([]);

  useEffect(() => {
    fetch(`/api/recipes?category=${id}`)
      .then((response) => response.json())
      .then((data) => setRecipes(data));
  }, [id]);

  return (
    <>
      <Header>
        <h1>Recipes in "{getCategoryName(id || '')}"</h1>
      </Header>
      <Container>
        {recipes.map((recipe) => {
          return (
            <Result onClick={() => navigate(`/recipe/${recipe._id}`)}>
              <ImageContainer>
                <Image recipe={recipe} width={recipe.imageId ? 150 : 100} />
              </ImageContainer>
              <span>{recipe.name}</span>
            </Result>
          );
        })}
      </Container>
    </>
  );
};

export default CategoryPage;
