import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import Button from '../components/Button';
import IconButton from '../components/IconButton';
import Modal from '../components/Modal';
import Spacer from '../components/Spacer';
import useUser from '../hooks/useUser';
import type { Recipe } from '../types/recipes';

const RecipeRow = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 4px;
`;

const ManageRecipesPage = () => {
  const [recipes, setRecipes] = useState<Array<Recipe>>([]);
  const [toDelete, setToDelete] = useState<Recipe | null>(null);

  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/api/recipes')
      .then((response) => response.json())
      .then((data) => setRecipes(data));
  }, []);

  const deleteRecipe = async (recipeId: string) => {
    const authtoken = user && (await user.getIdToken());
    if (!authtoken) return;

    const headers: HeadersInit = { authtoken };
    fetch(`/api/recipes/${recipeId}`, { headers, method: 'DELETE' }).then(
      (response) => {
        if (response.ok) {
          setRecipes(recipes.filter((recipe) => recipe._id !== recipeId));
        }
      }
    );
  };

  return (
    <>
      <Button icon="add_document" onClick={() => navigate(`/add`)}>
        Add Recipe
      </Button>
      <Spacer height={32} />
      {recipes.map((recipe) => (
        <RecipeRow key={recipe._id}>
          {recipe.name}
          <IconButton
            icon="pencil"
            tooltipId="recipe-action-tooltip"
            tooltipContent="Edit Recipe"
            onClick={() => navigate(`/edit/${recipe._id}`)}
          />
          <IconButton
            icon="remove"
            tooltipId="recipe-action-tooltip"
            tooltipContent="Delete Recipe"
            onClick={() => setToDelete(recipe)}
          />
        </RecipeRow>
      ))}
      <Tooltip id="recipe-action-tooltip" />
      {toDelete && (
        <Modal
          title="Delete Recipe"
          body={`Are you sure you want to delete the recipe "${toDelete.name}"?`}
          footer={
            <>
              <Button onClick={() => setToDelete(null)}>Cancel</Button>
              <Button onClick={() => deleteRecipe(toDelete._id).then(() => setToDelete(null))}>Delete</Button>
            </>
          }
        />
      )}
    </>
  );
};

export default ManageRecipesPage;
