import { ReactNode } from 'react';
import styled from 'styled-components';

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #333333;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #c7d8e8;
  color: #272b46;
  padding: 8px;
`
const TitleDiv = styled.div`
  display: flex;
  height: 30px;
  padding: 8px;
  font-weight: 700;
`;
const BodyDiv = styled.div`
  display: flex;
  padding: 8px;
`
const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px;
`

const Modal = ({ title, body, footer }: { title: string, body: ReactNode, footer: ReactNode }) => {
  return (
    <MainDiv>
        <TitleDiv>{title}</TitleDiv>
        <BodyDiv>{body}</BodyDiv>
        <FooterDiv>{footer}</FooterDiv>
    </MainDiv>
  );
};

export default Modal;
