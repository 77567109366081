import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getRecipe } from '../api/recipes';
import Image from '../components/Image';
import Intro from '../components/Intro';
import Spacer from '../components/Spacer';
import Spinner from '../components/Spinner';
import type { Recipe } from '../types/recipes';
import { getCategoryName } from '../utils/categories';
import { displayNewLines } from '../utils/recipes';

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
`;
const Contents = styled.div`
  display: flex;
`;
const Sidebar = styled.div`
  width: 244px;
  padding: 25px 30px 30px 30px;
`;
const Main = styled.div`
  background: #4c5070;
  max-width: 640px;
  padding: 20px 40px;
`;
const RecipeIntro = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 20px 20px 30px;
`;
const RecipeName = styled.h2`
  color: #272b46;
  font-size: 24px;
  font-weight: normal;
  padding-bottom: 18px;
`;
const TopProperty = styled.div`
  color: #6570b6;
  font-size: 16px;
  line-height: 21px;
  padding: 2px 0;
`;
const Bold = styled.span`
  font-weight: bold;
`;
const SidebarHeader = styled.h2`
  color: #6791b8;
  font-size: 24px;
  font-weight: normal;
  padding-bottom: 4px;
`;
const SidebarLink = styled.a`
  color: #eb8400;
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: #6791b8;
  }
`;
const ContentParagraph = styled.p`
  padding: 8px 0;
`;
const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  padding: 4px 0;
`;
const SpaceLines = styled.div`
  > p {
    padding-bottom: 16px;
  }
`;
const Ingredient = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RecipePage = () => {
  const navigate = useNavigate();
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState<Recipe | null>(null);

  useEffect(() => {
    if (recipeId) {
      getRecipe(recipeId).then(setRecipe);
    }
  }, [recipeId]);

  if (!recipe) {
    return <Spinner />;
  }

  return (
    <MainLayout>
      <Intro>
        <RecipeIntro>
          {recipe.imageId && <Image recipe={recipe} height={250} />}
          <div>
            <RecipeName>{recipe.name}</RecipeName>
            {recipe.totalTime > 0 && (
              <TopProperty>
                <Bold>Total Time:</Bold>
                <Spacer width={4} />
                {recipe.totalTime}
              </TopProperty>
            )}
            {recipe.yield && (
              <TopProperty>
                <Bold>Yield:</Bold>
                <Spacer width={4} />
                {recipe.yield}
              </TopProperty>
            )}
          </div>
        </RecipeIntro>
      </Intro>
      <Contents>
        <Sidebar>
          <SidebarHeader>Category</SidebarHeader>
          <List>
            <ListItem>
              <SidebarLink
                onClick={() => navigate(`/category/${recipe.category}`)}
              >
                {getCategoryName(recipe.category)}
              </SidebarLink>
            </ListItem>
          </List>
          {recipe.keywords && (
            <>
              <Spacer height={16} />
              <SidebarHeader>Keywords</SidebarHeader>
              <List>
                {recipe.keywords.split(',').map((keyword) => {
                  const term = keyword.trim();
                  return (
                    <ListItem key={term}>
                      <SidebarLink
                        onClick={() => navigate(`/search?q=${term}`)}
                      >
                        {term}
                      </SidebarLink>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Sidebar>
        <Main>
          <h4>Ingredients</h4>
          <ContentParagraph>
            {displayNewLines(recipe.ingredients, 'span').map((line) => (
              <Ingredient>
                <input type="checkbox" />
                {line}
              </Ingredient>
            ))}
          </ContentParagraph>
          <h4>Directions</h4>
          <ContentParagraph>
            <SpaceLines>{displayNewLines(recipe.directions)}</SpaceLines>
          </ContentParagraph>
        </Main>
      </Contents>
    </MainLayout>
  );
};

export default RecipePage;
